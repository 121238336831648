// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apple-sign-in-error-js": () => import("./../../../src/pages/appleSignInError.js" /* webpackChunkName: "component---src-pages-apple-sign-in-error-js" */),
  "component---src-pages-apple-sign-in-success-js": () => import("./../../../src/pages/appleSignInSuccess.js" /* webpackChunkName: "component---src-pages-apple-sign-in-success-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/deleteAccount.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboards-js": () => import("./../../../src/pages/leaderboards.js" /* webpackChunkName: "component---src-pages-leaderboards-js" */),
  "component---src-pages-licenses-js": () => import("./../../../src/pages/licenses.js" /* webpackChunkName: "component---src-pages-licenses-js" */),
  "component---src-pages-more-games-js": () => import("./../../../src/pages/moreGames.js" /* webpackChunkName: "component---src-pages-more-games-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-purchasecancel-js": () => import("./../../../src/pages/purchasecancel.js" /* webpackChunkName: "component---src-pages-purchasecancel-js" */),
  "component---src-pages-purchasesuccess-js": () => import("./../../../src/pages/purchasesuccess.js" /* webpackChunkName: "component---src-pages-purchasesuccess-js" */)
}

